import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const MediaText = props => {
  const { media, text, position, children, style } = props

  return (
    <section className={`media-text-section ${position}`} style={style}>
      <div
        className="text"
        data-sal="slide-up"
        data-sal-duration="600"
        data-sal-delay="0"
        data-sal-easing="ease"
      >
        <p>{text}</p>
      </div>
      <div
        className="media"
        data-sal="slide-up"
        data-sal-duration="1300"
        data-sal-delay="200"
        data-sal-easing="ease"
      >
        <GatsbyImage loading="eager" image={media} alt="img alt" />
        {children}
      </div>
    </section>
  )
}
export default MediaText
