import * as React from "react"
import { Link, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MediaText from "../components/mediatext"
import { Arrow } from "../components/logos"
import "../styles/styles.scss"

export default function IndexPage(props) {
  const { data } = props

  return (
    <Layout>
      <Seo title="Home" />
      <section className="hero">
        <h1
          style={{
            paddingTop: "10vh",
            paddingBottom: "1vh",
          }}
        >
          Mana är en hållbarhetsstudio. Vi hjälper företag att framtidssäkra
          sina verksamheter genom att ta tillvara på affärsmässiga möjligheter
          som uppstår i den hållbara omställningen.
        </h1>
        <a
          style={{
            marginBottom: "10vh",
          }}
          href="mailto:nico@wearemana.se"
        >
          <Arrow />
          Kontakta oss
        </a>
      </section>
      <GatsbyImage
        loading="eager"
        image={data?.heroImg?.childImageSharp?.gatsbyImageData}
        alt="img alt"
        style={{ width: "100%" }}
      />
      <MediaText
        position={"right"}
        media={data?.wavesImg?.childImageSharp?.gatsbyImageData}
        text={
          "I vårt team ingår strateger, hållbarhetsexperter, designers och utvecklare som tillsammans arbetar för att hjälpa företag att genom design, teknik och innovation identifiera och ta tillvara på affärsmässiga möjligheter som uppstår i den hållbara omställningen."
        }
        style={{ marginTop: "10vh" }}
      ></MediaText>

      <section className="three-column-section">
        <div
          className="col-1"
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          <p>
            Mana är systerbyrå till den digitala byrån{" "}
            <a href="https://accomplice.se" target="_blank">
              Accomplice
            </a>{" "}
            och arbetar bland andra med:
          </p>
        </div>

        <div className="col-2">
          <ul
            className="partners-list"
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-delay="0"
            data-sal-easing="ease"
          >
            <li>Adobe</li>
            <li>Järvaveckan</li>
            <li>MTAB</li>
            <li>NCC</li>
            <li>Sonos</li>
            <li>Xiaomi</li>
            <li>Scandic</li>
            <li>IKEA</li>
            <li>Sony Music</li>
          </ul>
          <ul
            className="partners-list"
            data-sal="slide-up"
            data-sal-duration="1300"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            <li>Hemnet</li>
            <li>Energiföretagen</li>
            <li>FTI</li>
            <li>MW Group</li>
            <li>Malmö Konsthall</li>
            <li>ÅWL Arkitekter</li>
            <li>Pembroke</li>
            <li>Frisq</li>
            <li>Clarion Hotels</li>
          </ul>
        </div>
      </section>
      {/* <GatsbyImage
        loading="eager"
        image={data?.bwImg?.childImageSharp?.gatsbyImageData}
        alt="img alt"
        style={{ width: '100%' }}
      /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    heroImg: file(relativePath: { eq: "images/Mask Group 3@2x.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2220
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    wavesImg: file(relativePath: { eq: "images/waves-net@4x.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bwImg: file(relativePath: { eq: "images/bw-hike.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
